class Accessibility extends HTMLElement {
  constructor() {
    super();

    this.settings = { fontSize: 16 };
    this.loadSettings();

    this.buttons = this.querySelectorAll("button");

    this.buttons.forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();
        switch (button.dataset.id) {
          case "dyslexie":
            this.toggleDyslexic(button);
            break;
          case "font_minus":
            this.decreaseFont();
            break;
          case "font_add":
            this.increaseFont();
            break;
          case "translate":
            this.togglePopup("translate");
            break;
        }
      });
    });

    window.addEventListener("scroll", () => {
      this.querySelectorAll(".accessibility-popup").forEach((popup) => {
        popup.classList.remove("active");
      });
    });

    this.querySelectorAll("a[data-lang]").forEach((a) => {
      a.addEventListener("click", (e) => {
        e.preventDefault();
        this.translate(e.target.dataset.lang);
      });
    });
  }

  track(name, opts = {}) {
    if (typeof gtag === typeof undefined) return;

    gtag("event", name, opts);
  }

  decreaseFont() {
    this.settings.fontSize -= 2;

    if (this.settings.fontSize <= 10) {
      this.settings.fontSize = 10;
    }

    this.track("DecreaseFontSize", { size: this.settings.fontSize });

    this.setFontSize();
  }

  increaseFont() {
    this.settings.fontSize += 2;

    if (this.settings.fontSize >= 26) {
      this.settings.fontSize = 26;
    }

    this.track("InreaseFontSize", { size: this.settings.fontSize });

    this.setFontSize();
  }

  setFontSize() {
    let root = document.documentElement;
    root.style.setProperty("font-size", `${this.settings.fontSize}px`);
    this.cacheValue("font_size", this.settings.fontSize);
  }

  toggleDyslexic() {
    let root = document.documentElement;
    this.track("ToggleDyslexicFont");
    if (this.settings.dyslexie) {
      root.style.setProperty("--header-font-family", null);
      root.style.setProperty("--body-font-family", null);
      root.style.setProperty("--secondary-font-family", null);
      this.cacheValue("dyslexie", false);
    } else {
      root.style.setProperty(
        "--header-font-family",
        getComputedStyle(root).getPropertyValue("--dyslexic-font")
      );
      root.style.setProperty(
        "--body-font-family",
        getComputedStyle(root).getPropertyValue("--dyslexic-font")
      );
      this.cacheValue("dyslexie", true);
    }
  }

  cacheValue(key, value) {
    this.settings[key] = value;
    localStorage.setItem(
      "accessibility_settings",
      JSON.stringify(this.settings)
    );
  }

  loadSettings() {
    let data = localStorage.getItem("accessibility_settings");

    if (!data) return;

    data = JSON.parse(data);
    this.settings = { ...this.settings, ...data };

    for (var key of Object.keys(this.settings)) {
      if (this.settings[key] === null || !this.settings[key]) continue;

      switch (key) {
        case "dyslexie":
          this.settings[key] = !this.settings[key];
          this.toggleDyslexic();
          break;
        case "font_size":
          this.setFontSize();
        case "translation":
          this.querySelector('button[data-id="translate"] .current').innerText =
            this.settings[key].name;
        default:
          break;
      }
    }
  }

  togglePopup(target) {
    const popup = this.querySelector(
      `.accessibility-popup[data-target=${target}]`
    );

    if (!popup) return;

    popup.classList.toggle("active");
  }

  translate(locale) {
    const el = this.querySelector(".goog-te-combo");

    if (!el) return;

    let locale_el = this.querySelector(`[data-lang="${locale}"]`);

    this.querySelector('button[data-id="translate"] .current').innerText =
      locale_el.innerText;

    this.cacheValue("translation", {
      locale,
      name: locale_el ? locale_el.innerHTML : false
    });
    this.track("Translation", { language: locale });

    el.value = locale;

    if ("createEvent" in document) {
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      el.dispatchEvent(evt);
    } else {
      el.fireEvent("onchange");
    }

    this.togglePopup("translate");
  }
}

customElements.define("accessibility-options", Accessibility);
