//Import Core Styles
import './app.scss';

import Header from './core/header/header';
import Hero from './hero/hero';

//Core JS Functions
import './core/global'
import "./core/accessibility";

import css from 'uikit/dist/css/uikit.css';
import Icons from 'uikit/dist/js/uikit-icons';
import UIkit from 'uikit/dist/js/uikit.js'

// loads the Icon plugin
UIkit.use(Icons);

//JS Plugins
//import './plugins/map-controller'
//import './plugins/testimonials'

(function () {

	//Initialise Header
	new Header(document.querySelector('header[uk-sticky]'));

	//Initialise Banner Module
	let banner = document.querySelector('.banner_container');
	if (banner !== null) {
		new Hero(banner)
	}

})();
