'use strict';
(function () {

	function smoothScroll(hash, e) {
		const link = hash.replace("#","");
		const target = document.getElementById(link);

		if (target !== null) {
			e ? e.preventDefault() : false;
			target.scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			});
		}
	}

	document.addEventListener("DOMContentLoaded", function (event) {

		//Add Flex Video to YouTube & Video Embeds
		document.querySelectorAll('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').forEach(iframe => {
			var wrapper = document.createElement('div');
			wrapper.classList.add('flex-video');

			if (iframe.clientWidth / iframe.clientHeight > 1.5) {
				wrapper.classList.add('widescreen');
			}

			iframe.parentNode.insertBefore(wrapper, iframe);
			wrapper.appendChild(iframe);
		});

		document.querySelectorAll('a.smooth').forEach(el => {
			el.addEventListener('click', e => {
				smoothScroll(e.target.hash, e);
			});
		});

		if (window.location.hash) {
			console.log(window.location.hash);
			smoothScroll(window.location.hash);
		}

	});
})();
