import Swiper, { Navigation, Pagination } from 'swiper';
import Header from '../core/header/header';

export default class Banner {
	constructor(el) {
		this._el = el;
		this._slider = el.querySelector('.banner-slider')

		window.addEventListener('resize', () => {
			this.calculateHeight();
		});

		if (this._slider !== null) {
			this.initSwiper();
		}

		this.calculateHeight();
	}

	initSwiper() {
		this.swiper = new Swiper(this._slider, {
			// Optional parameters
			direction: 'horizontal',
			modules: [Navigation, Pagination],
			init: false,
			slidesPerView: 1,
			loop: this._slider.querySelectorAll('.swiper-slide').length > 1,
			autoplay: {
				delay: 5000,
			},
			pagination: {
				el: '.swiper-pagination',
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			// And if we need scrollbar
			scrollbar: {
				el: '.swiper-scrollbar',
			},
		});

		this.swiper.on('init', () => {
			this.calculateHeight();
		});

		this.swiper.on('slideChange', () => {

		});

		this.swiper.init();
	}

	calculateHeight() {
		let header = new Header();

		var height = 0,
		_viewport = window.innerHeight - header.getHeight();

		console.log(header.getHeight());

		const heightAttr = this._el.getAttribute('data-banner-height') || 'third';

		switch (heightAttr) {
			case 'two_thirds':
				height = (_viewport / 3 ) * 2;
			break;
			case 'half':
				height = _viewport / 2;
			break;
			case 'full':
				height = _viewport;
			break;
			case 'custom':
				height = this._el.getAttribute('data-banner-height-custom');
			break;
			case 'third':
			default:
				height = _viewport / 3;
			break;
		}

		// if (Foundation.MediaQuery.is('small only')) {
		// 	height = height - 150;
		// }

		if (height < 150) {
			height = 150;
		}

		//Check Banner height fits all the content inside
		this._el.querySelectorAll('.banner-item').forEach(bannerItem => {
			// if (header.isTransparent() && !Foundation.MediaQuery.is('small only')) {
			// 	bannerItem.style.paddingTop = header.getHeight() + 'px';
			// }

			if (bannerItem.offsetHeight > height) {
				height = bannerItem.offsetHeight;
			}
		});

		this._el.style.height = height + 'px';
	}
}
