"use strict";
import "./header.scss";

let instance;

export default class Header {
    constructor(el) {
        if (instance) {
            return instance;
        }

        instance = this;

        this.el = el.querySelector(".uk-container");
    }

    getHeight() {
        return this.el.offsetHeight;
    }

    isTransparent() {
        return this.el.classList.contains("transparent");
    }
}

//
// Search
// - toggle search form on click
//

window.addEventListener("load", function () {
    // const searchRevealButton = document.querySelector("#search-reveal-button");
    const searchForm = document.querySelector("#search-form");

    document
        .querySelector("#search-reveal-button")
        .addEventListener("click", function (e) {
            console.log("search click");
            e.preventDefault();
            searchForm.classList.toggle("hidden");
            // add focus to input inside searchForm
            searchForm.querySelector("input").focus();
            // when focus is lost, hide search form
            searchForm
                .querySelector("input")
                .addEventListener("blur", function (e) {
                    console.log("search blur");
                    searchForm.classList.add("hidden");
                });
        });
});
